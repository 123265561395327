<template>
    <div class="box">
        <div class="nationwideData">
            <div class="head flex-align-between">
                <div class="filtrate"></div>
                <div class="screenTitle">首师优字数据报告</div>
                <div class="filtrate flex-align-center">
                    <div class="filtrate_son">
                        <el-select v-model="termValue" placeholder="请选择" :popper-append-to-body="false" size='small'
                            @change="termChange">
                            <el-option v-for="item in termList" :key="item.key" :label="item.label" :value="item.termName">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="province_sel">
                        <el-select v-model="provincevalue" placeholder="请选择" :popper-append-to-body="false" size='small'
                            @change="provinceChange">
                            <el-option v-for="item in provinceData" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="collect flex-align-between">
                        <img src="../../assets/datascreen/export.png" alt="" />
                        <span style="cursor:pointer;" @click="getexcelByNationwide" v-if="getexcelByNationwideState">
                            导出数据</span>
                        <span v-else> 生成中 <i class="el-icon-loading"></i></span>
                    </div>
                    <div class="collect flex-align-center" v-if="statistics == 1">
                        <span style="cursor: pointer;" @click="getback" class="getback">首师优字</span>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="Lside">
                    <div class="applctnfvrscrss">
                        <div class="diamond_title flex-align-center">各类课程应用情况</div>
                        <div class="primaryCoverage" v-if="pieValue.length != 0">
                            <Pie :pieValue="pieValue"></Pie>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>

                    </div>
                    <div class="mnthlypplctnsttstcs">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div>每月应用统计</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt="">
                                <span style="cursor:pointer;" @click="getexcelByNationwideByMonthWeek"
                                    v-if="getexcelByNationwideByMonthWeekState"> 导出数据</span>
                                <span v-else> 生成中 <i class="el-icon-loading"></i></span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="bothLineName.length != 0">
                            <BothLine :bothLineName="bothLineName" :bothLineValue="bothLineValue"></BothLine>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                    <div class="applctnsttstcsfrchgrd">
                        <div class="diamond_title flex-align-center">各年级应用统计</div>
                        <div class="primaryCoverage" v-if="Dbarvalue.length != 0">
                            <Dbar :Dbarname="Dbarname" :Dbarvalue="Dbarvalue" :DbarmaxValue="DbarmaxValue"></Dbar>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                    <div class="prtcpntssttstcs">
                        <div class="diamond_title flex-align-center">参与人数统计</div>
                        <div class="primaryCoverage" v-if="pictorialValue.length != 0">
                            <PictorialBar :pictorialName="pictorialName" :pictorialValue="pictorialValue"></PictorialBar>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                </div>
                <div class="middle">
                    <div class="infrmtn">
                        <div class="infrmtn_line flex-align-center ">
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>学校总数</span>
                                <span>{{ schoolNum || 0 }}所</span>
                            </div>
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>教师人数</span>
                                <span>{{ teacherNum || 0 }}人</span>
                            </div>
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>上课总数</span>
                                <span>{{ attendClassNum || 0 }}节</span>
                            </div>
                        </div>
                        <div class="infrmtn_line flex-align-center">
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>学生人数</span>
                                <span>{{ studentNum || 0 }}人</span>
                            </div>
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>学习课程</span>
                                <span>{{ courseNum || 0 }}节</span>
                            </div>
                            <div class="circle-item circle-item-bg flex-column-center">
                                <span>测评总人次</span>
                                <span>{{ appraisalNum || 0 }}次</span>
                            </div>
                        </div>
                    </div>
                    <div class="map" id="mymap"></div>
                    <div class="applctnfcrstyps">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div>各省市不同课型应用情况</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt="">
                                <span style="cursor:pointer;" @click="getApplyDetailExcel" v-if="getApplyDetailExcelState">
                                    导出数据</span>
                                <span v-else> 生成中 <i class="el-icon-loading"></i></span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="ApplicationData.length != 0">
                            <StackBar :lessontypeName="lessontypeName" :lessontypeValue="lessontypeValue"
                                :ApplicationMaxData="ApplicationMaxData" :termId="termId"></StackBar>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                </div>
                <div class="Rside">
                    <div class="applctndtls">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div>各省市应用情况</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt="">
                                <span style="cursor:pointer;" @click="getapplyByNationwideExcel"
                                    v-if="getapplyByNationwideExcelState"> 导出数据</span>
                                <span v-else> 生成中 <i class="el-icon-loading"></i></span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="situationApp.length != 0">
                            <div class="ApplicationdetailsT_head_bg">
                                <div class="th1 flex-align-center">省市</div>
                                <div class="th2 flex-align-center">学校数量</div>
                                <div class="th3 flex-align-center">注册教师</div>
                                <div class="th4 flex-align-center">参与率</div>
                                <div class="th5 flex-align-center">应用总次数</div>
                            </div>
                            <div class="ApplicationdetailsT_box">
                                <div class="Application_list" v-for="(item, index) in situationApp" :key="index">
                                    <div class="th1 flex-align-center goprovince" @click="goprovince(item.name, item.areaId
                                    )">{{ item.name }}
                                    </div>
                                    <div class="th2 flex-align-center">{{ item.schoolNum }}</div>
                                    <div class="th3 flex-align-center">{{ item.teaNum }}</div>
                                    <div class="th4 flex-align-center">{{ Math.round(item.joinRate * 100 * 100) / 100 }}%
                                    </div>
                                    <!-- {{ item.joinRate.toFixed(2) * 100 }}% -->
                                    <div class="th5 flex-align-center">{{ item.applyNum }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                    <div class="applctndtls">
                        <div class="diamond_title flex-align-between">
                            <div class="placeholder"></div>
                            <div>各省市测评情况</div>
                            <div class="placeholder">
                                <img src="../../assets/datascreen/export.png" alt="">
                                <span style="cursor:pointer;" @click="getEvalExcel" v-if="getEvalExcelState"> 导出数据</span>
                                <span v-else> 生成中 <i class="el-icon-loading"></i></span>
                            </div>
                        </div>
                        <div class="primaryCoverage" v-if="testSituation.length != 0">
                            <div class="ApplicationdetailsT_head_bg">
                                <div class="th1 flex-align-center">省市</div>
                                <div class="th2 flex-align-center">学生人数</div>
                                <div class="th3 flex-align-center">测评总人次</div>
                                <div class="th4 flex-align-center">统一测评人次</div>
                                <div class="th5 flex-align-center">成绩良好及以上</div>
                            </div>
                            <div class="ApplicationdetailsT_box">
                                <div class="Application_list" v-for="(item, index) in testSituation" :key="index">
                                    <div class="th1 flex-align-center">{{ item.name }}</div>
                                    <div class="th2 flex-align-center">{{ item.stuNum }}</div>
                                    <div class="th3 flex-align-center">{{ item.evalTotalNum }}</div>
                                    <div class="th4 flex-align-center">{{ item.unifyEvalNum }}</div>
                                    <div class="th5 flex-align-center">{{ item.goodNum }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>
                    <div class="evaluationScore">
                        <div class="diamond_title flex-align-center">
                            <div> 各时段不同课型应用情况</div>
                        </div>
                        <div class="primaryCoverage" v-if="timeFrame.length != 0">
                            <MultidimensionalBar :timeFrame="timeFrame" :termId="termId"></MultidimensionalBar>
                        </div>
                        <div class="primaryCoverage flex-align-center" v-else>
                            <PlaceholderScreen></PlaceholderScreen>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlaceholderScreen from '../DataScreen/placeholderBox/placeholderScreen.vue';
import Pie from './prvncndctyAssembly/pie.vue';
import BothLine from './prvncndctyAssembly/bothLine.vue';
import Dbar from './prvncndctyAssembly/3dbar.vue';
import StackBar from './prvncndctyAssembly/stackBar.vue';
import MultidimensionalBar from './prvncndctyAssembly/multidimensionalBar.vue';
import PictorialBar from './prvncndctyAssembly/pictorialBar.vue';
import geoJson from './json/china.json';
export default {
    //import引入组件才能使用
    components: {
        PlaceholderScreen,
        Pie,
        BothLine,
        Dbar,
        StackBar,
        MultidimensionalBar,
        PictorialBar,
    },
    props: {},
    data() {
        return {
            provinceData: [
                {
                    id: '0',
                    name: '全国'
                },
            ],
            provincevalue: '全国',
            //学期
            termList: [],
            termValue: '',
            termId: '',
            // 学校总数
            schoolNum: 0,
            // 教师人数
            teacherNum: 0,
            // 上课总数
            attendClassNum: 0,
            // 学生人数
            studentNum: 0,
            // 学习课程
            courseNum: 0,
            // 测评总人数
            appraisalNum: 0,
            //各类课程应用情况
            pieValue: [],
            //每月应用统计
            bothLineName: [],
            bothLineValue: [],
            //各年级应用统计
            Dbarname: [],
            Dbarvalue: [],
            DbarmaxValue: [],
            //应用详情-不同课型应用情况
            ApplicationData: [],
            // 不同课型应用情况
            lessontypeName: [],
            lessontypeValue: {
                data1: [],
                data2: [],
                data3: [],
                data4: [],
            },
            ApplicationMaxData: [],
            // 参与人数统计
            pictorialName: [],
            pictorialValue: [],
            // 各地区应用情况
            situationApp: [],
            mapData: [],
            // 各地区测评情况
            testSituation: [],
            //各时段不同课型应用情况
            timeFrame: [],
            statistics: '',
            //下载状态
            getexcelByNationwideByMonthWeekState: true,
            getapplyByNationwideExcelState: true,
            getEvalExcelState: true,
            getApplyDetailExcelState: true,
            getexcelByNationwideState:true,
        };
    },
    // 计算属性
    computed: {},
    // 监听data中的数据变化
    watch: {},
    // 方法集合
    methods: {
        async provinceChange(value) {
            let name = this.provinceData.find(option => option.id === value).name;

            if (name != '全国' && name != '北京' && name != '重庆' && name != '上海' && name != '天津') {
                let routeData = this.$router.resolve({ path: '/ProvinceData', query: { rank: 0, id: value, name: name, date: this.termId } });
                window.open(routeData.href, '_blank');
            } else if (name == '北京' || name == '重庆' || name == '上海' || name == '天津') {
                let areaId = await this.getregionid(value);
                if (areaId) {
                    let routeData = this.$router.resolve({ path: '/CityData', query: { rank: 1, id: areaId, name: name + '市', date: this.termId } });
                    window.open(routeData.href, '_blank');
                }
            }
        },
        async goprovince(value, id) {
            if (value == '北京' || value == '重庆' || value == '上海' || value == '天津') {
                let areaId = await this.getregionid(id);
                if (areaId) {
                    let routeData = this.$router.resolve({ path: '/CityData', query: { rank: 1, id: areaId, name: value + '市', date: this.termId } });
                    window.open(routeData.href, '_blank');
                }

            } else {
                let routeData = this.$router.resolve({ path: '/ProvinceData', query: { rank: 0, id: id, name: value, date: this.termId } });
                window.open(routeData.href, '_blank');
            }
        },
        //直辖市直接跳转
        async getregionid(id) {
            let data = {
                termKey: this.termId,
                level: 0,
                areaId: id,
            };
            let resData = await this.$Api.DataScreen.getApplyDetail(data);
            return resData.data[0].areaId;
        },
        //应用地图
        map_graphics() {
            this.$echarts.init(document.getElementById("mymap")).dispose();
            let that = this;
            let myChart = this.$echarts.init(
                document.getElementById("mymap")
            );
            this.$echarts.registerMap('china', geoJson);
            let geoCoordMap = {
                '江苏省': [118.8062, 31.9208],
                '黑龙江省': [127.9688, 45.368],
                '内蒙古自治区': [110.3467, 41.4899],
                '吉林省': [125.8154, 44.2584],
                '北京市': [116.4551, 40.2539],
                '辽宁省': [123.1238, 42.1216],
                '河北省': [114.4995, 38.1006],
                '天津市': [117.4219, 39.4189],
                '山西省': [112.3352, 37.9413],
                '陕西省': [109.1162, 34.2004],
                '甘肃省': [103.5901, 36.3043],
                '宁夏回族自治区': [106.3586, 38.1775],
                '青海省': [101.4038, 36.8207],
                '新疆维吾尔自治区': [87.9236, 43.5883],
                '四川省': [103.9526, 30.7617],
                '重庆市': [108.384366, 30.439702],
                '山东省': [117.1582, 36.8701],
                '河南省': [113.4668, 34.6234],
                '安徽省': [117.29, 32.0581],
                '湖北省': [114.3896, 30.6628],
                '浙江省': [119.5313, 29.8773],
                '福建省': [119.4543, 25.9222],
                '江西省': [116.0046, 28.6633],
                '湖南省': [113.0823, 28.2568],
                '贵州省': [106.6992, 26.7682],
                '云南省': [102.9199, 25.4663],
                '广东省': [113.12244, 23.009505],
                '广西壮族自治区': [108.479, 23.1152],
                '海南省': [110.3893, 19.8516],
                '上海市': [121.4648, 31.2891],
                '西藏自治区': [91.11, 29.97],
            };
            // let customerBatteryCityData = [
            //     { name: '北京市', value: 70 },
            //     { name: '内蒙古自治区', value: 80 },
            //     { name: '甘肃省', value: 50 },
            //     { name: '湖南省', value: 130 },
            //     { name: '重庆市', value: 80 },
            //     { name: '山西省', value: 20 },
            //     { name: '上海市', value: 88 },
            //     { name: '西藏自治区', value: 80 },
            //     { name: '河南省', value: 56 },
            // ]
            let customerBatteryCityData = this.mapData;
            myChart.setOption({
                geo: [
                    {
                        map: 'china',
                        aspectScale: 0.9,
                        roam: false, // 是否允许缩放
                        zoom: 1.2, // 默认显示级别
                        layoutSize: '115%',
                        layoutCenter: ['50%', '65%'],
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                areaColor: {
                                    type: 'linear-gradient',
                                    x: 0,
                                    y: 400,
                                    x2: 0,
                                    y2: 0,
                                    colorStops: [{
                                        offset: 0,
                                        color: 'rgba(37,108,190,0.3)' // 0% 处的颜色
                                    }, {
                                        offset: 1,

                                        color: 'rgba(15,169,195,0.3)' // 50% 处的颜色
                                    }],
                                    global: true // 缺省为 false
                                },
                                borderColor: '#02aeff',
                                borderWidth: this.graphicRatio(1)
                            },
                            emphasis: {
                                areaColor: {
                                    type: 'linear-gradient',
                                    x: 0,
                                    y: 400,
                                    x2: 0,
                                    y2: 0,
                                    colorStops: [{
                                        offset: 0,
                                        color: 'rgba(37,108,190,1)' // 0% 处的颜色
                                    }, {
                                        offset: 1,
                                        color: 'rgba(15,169,195,1)' // 50% 处的颜色
                                    }],
                                    global: true // 缺省为 false
                                },
                                borderColor: '#02aeff',
                                borderWidth: this.graphicRatio(1)
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                areaColor: '#0160AD'
                            },
                            label: {
                                show: false
                            }
                        },
                        regions: [{
                            name: '南海诸岛',
                            selected: false,
                            emphasis: {
                                disabled: true
                            },
                            itemStyle: {
                                areaColor: '#00000000',
                                borderColor: '#00000000'
                            }
                        }],
                        select: {
                            disabled: true
                        },
                        zlevel: 3
                    },
                    {
                        map: 'china',
                        aspectScale: 0.9,
                        roam: false, // 是否允许缩放
                        zoom: 1.2, // 默认显示级别
                        layoutSize: '115%',
                        layoutCenter: ['50%', '65%'],
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            normal: {
                                borderColor: 'rgba(192,245,249,.6)',
                                borderWidth: this.graphicRatio(2),
                                shadowColor: '#2C99F6',
                                shadowOffsetY: 0,
                                shadowBlur: 120,
                                areaColor: 'rgba(29,85,139,.2)'
                            },
                        },
                        select: {
                            disabled: true
                        },
                        regions: [{
                            name: '南海诸岛',
                            selected: false,
                            emphasis: {
                                disabled: true
                            },
                            itemStyle: {
                                areaColor: '#00000000',
                                borderColor: '#00000000'
                            }
                        }],
                        zlevel: 2,
                        silent: true
                    },
                    {
                        map: 'china',
                        aspectScale: 0.9,
                        roam: false, // 是否允许缩放
                        zoom: 1.2, // 默认显示级别
                        layoutSize: '115%',
                        layoutCenter: ['50%', '66.5%'],
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            areaColor: 'rgba(0,27,95,0.4)',
                            borderColor: '#004db5',
                            borderWidth: this.graphicRatio(1),
                        },
                        select: {
                            disabled: true
                        },
                        regions: [{
                            name: '南海诸岛',
                            selected: false,
                            emphasis: {
                                disabled: true
                            },
                            itemStyle: {
                                areaColor: '#00000000',
                                borderColor: '#00000000'
                            }
                        }],
                        zlevel: 1,
                        silent: true
                    }
                ],
                series: [
                    {
                        geoIndex: 0,
                        showLegendSymbol: true,
                        type: 'map',
                        roam: true,
                        label: {
                            show: false,
                            normal: {
                                show: false,
                                textStyle: {
                                    color: '#fff'
                                }
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    color: '#fff'
                                }
                            }
                        },
                        map: 'china', // 使用
                        regions: [{
                            name: '南海诸岛',
                            selected: false,
                            emphasis: {
                                disabled: true
                            },
                            itemStyle: {
                                areaColor: '#00000000',
                                borderColor: '#00000000'
                            }
                        }],
                        select: {
                            disabled: true
                        },
                        data: customerBatteryCityData
                    },
                    {
                        type: 'lines',
                        zlevel: 5,
                        effect: {
                            show: false,
                            symbolSize: 5 // 图标大小
                        },
                        lineStyle: {
                            width: this.graphicRatio(23), // 尾迹线条宽度
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(208, 127, 48,1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(250, 185, 120,1)'
                            }
                            ]),
                            opacity: 1, // 尾迹线条透明度
                            curveness: 0 // 尾迹线条曲直度
                        },
                        label: {
                            show: 0,
                            position: 'end',
                            formatter: '245'
                        },
                        silent: true,
                        data: that.lineData(geoCoordMap, customerBatteryCityData)
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        geoIndex: 0,
                        zlevel: 5,
                        label: {
                            show: true,
                            position: 'top',
                            formatter: (params) => customerBatteryCityData[params.dataIndex].name + ':' + customerBatteryCityData[params.dataIndex].value + '次',
                            padding: [4, 8],
                            backgroundColor: '#003F5E',
                            borderRadius: this.graphicRatio(5),
                            borderColor: '#67F0EF',
                            borderWidth: this.graphicRatio(1),
                            color: '#67F0EF',
                            fontSize: this.graphicRatio(12),
                        },
                        symbol: 'diamond',
                        // symbolSize: [23, 8],
                        symbolSize: [this.graphicRatio(22), this.graphicRatio(7)],
                        itemStyle: {
                            color: 'rgba(245, 164, 58,1)',
                            opacity: 1
                        },
                        silent: true,
                        data: that.scatterData(geoCoordMap, customerBatteryCityData)
                    },
                    {
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        geoIndex: 0,
                        zlevel: 4,
                        label: {
                            formatter: '{b}',
                            position: 'bottom',
                            color: '#fff',
                            fontSize: this.graphicRatio(12),
                            distance: 10,
                            show: true
                        },
                        symbol: 'diamond',
                        // symbolSize: [23, 8],
                        symbolSize: [this.graphicRatio(23), this.graphicRatio(8)],
                        itemStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: 'rgba(208, 127, 48,1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(250, 185, 120,1)'
                            }
                            ]),
                            opacity: 1
                        },
                        silent: true,
                        data: that.scatterData2(geoCoordMap, customerBatteryCityData)
                    }
                ],
            });
            myChart.on('click', function (params) {
                if (params['data'].name) {
                    if (params['data'].name != '北京市' && params['data'].name != '重庆市' && params['data'].name != '上海市' && params['data'].name != '天津市') {
                        let routeData = that.$router.resolve({ path: '/ProvinceData', query: { rank: 0, id: params['data'].id, name: params['data'].name, date: that.termId } });
                        window.open(routeData.href, '_blank');
                    } else {
                        that.provinceChange(params['data'].id)
                    }

                }

            });
            window.addEventListener("resize", () => {
                myChart.resize();
            });
        },
        // 动态计算柱形图的高度（定一个max）
        lineMaxHeight(customerBatteryCityData) {
            const maxValue = Math.max(...customerBatteryCityData.map(item => item.value))
            // return 0.9 / maxValue
            return 3 / maxValue
        },
        // 柱状体的主干
        lineData(geoCoordMap, customerBatteryCityData) {
            return customerBatteryCityData.map((item) => {
                return {
                    coords: [geoCoordMap[item.name], [geoCoordMap[item.name][0], geoCoordMap[item.name][1] + item.value * this.lineMaxHeight(customerBatteryCityData)]]
                }
            })
        },
        // 柱状体的顶部
        scatterData(geoCoordMap, customerBatteryCityData) {
            return customerBatteryCityData.map((item) => {
                return [geoCoordMap[item.name][0], geoCoordMap[item.name][1] + item.value * this.lineMaxHeight(customerBatteryCityData)]
            })
        },
        // 柱状体的底部
        scatterData2(geoCoordMap, customerBatteryCityData) {
            return customerBatteryCityData.map((item) => {
                return {
                    name: item.name,
                    value: geoCoordMap[item.name]
                }
            })
        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    this.termList = res.data;
                    this.termValue = this.termList[0].termName;
                    this.termId = this.termList[0].key;
                    this.apilist();
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        termChange(value) {
            this.termId = this.termList.find(option => option.termName === value).key;
            this.apilist();
        },
        //请求接口集合
        apilist() {
            //基础数据
            this.basicsStatistics(3);
            //各类课程应用情况
            this.basicsStatistics(7);
            //每月应用统计
            this.getapplyStatByMonth();
            //各年级应用统计
            this.getapplyStatByGrade();
            //参与人数统计(全国)
            // this.getcountParticipants();
            //各省市不同课型应用情况
            this.getApplyDetail();
            //各地区应用情况（全国）
            this.getapplyByNationwide();
            //各地区测评情况（全国）
            this.getEval();
            //各时段不同课型应用情况
            this.getCourseHourLogByAction();
        },
        //获取地区
        async getarea() {
            let data = {
                pid: 0,
                level: 0,
            };
            let resData = await this.$Api.DataScreen.getarea(data);
            this.provinceData = [...this.provinceData, ...resData.data];
            this.provincevalue = '全国'
        },
        //基础数据
        async basicsStatistics(chartid) {
            this.schoolNum = 0;
            this.teacherNum = 0;
            this.attendClassNum = 0;
            this.studentNum = 0;
            this.courseNum = 0;
            this.appraisalNum = 0;
            this.pieValue = [];
            this.pictorialName = [];
            this.pictorialValue = [];
            let data = {
                chart: chartid,
                level: 1,
                bindId: 0,
                termKey: this.termId,

            };
            let resData = await this.$Api.DataScreen.basicsStatistics(data);
            if (chartid == 3) {
                this.schoolNum = this.getSafeDataValue(resData.data, 'count_school');
                this.teacherNum = this.getSafeDataValue(resData.data, 'count_teacher');
                this.attendClassNum = this.getSafeDataValue(resData.data, 'tea_take');
                this.studentNum = this.getSafeDataValue(resData.data, 'count_student');
                this.courseNum = this.getSafeDataValue(resData.data, 'stu_take');
                this.appraisalNum = this.getSafeDataValue(resData.data, 'cp_all');


                // 对于 pictorialName 和 pictorialValue，需要同时判断两个路径的存在性
                if (
                    this.getSafeDataValue(resData.data, 'tea_join_num') !== null &&
                    this.getSafeDataValue(resData.data, 'stu_join_num') !== null
                ) {
                    this.pictorialName = ['教师参与人数', '学生参与人数'];
                    this.pictorialValue[0] = this.getSafeDataValue(resData.data, 'tea_join_num');
                    this.pictorialValue[1] = this.getSafeDataValue(resData.data, 'stu_join_num');
                }
            } else if (chartid == 7) {
                for (let key in resData.data) {
                    this.pieValue.push(
                        { value: resData.data[key].count, name: resData.data[key].name }
                    )
                }
                if (this.termId == '20231' || this.termId == '20232') {
                    this.pieValue = this.pieValue.filter(item => item.name !== '同步投影' && item.name !== '视频投影' && item.name !== '简洁版');
                } else {
                    this.pieValue = this.pieValue.filter(item => item.name !== '同步投影' && item.name !== '视频投影' && item.name !== '每日一字');
                }

            }
        },
        getSafeDataValue(data, path, defaultVal = null) {
            if (path == 'count_teacher' || path == 'count_school' || path == 'count_student') {
                return data && data.hasOwnProperty(path) ? data[path]['result'] : defaultVal;
            } else {
                return data && data.hasOwnProperty(path) ? data[path]['count'] : defaultVal;
            }
            // return data && data.hasOwnProperty(path) ? data[path]['count'] : defaultVal;
        },
        //获取每月应用统计
        async getapplyStatByMonth() {
            this.bothLineName = [];
            this.bothLineValue = [];
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let resData = await this.$Api.DataScreen.getapplyStatByMonth(data);
            resData.data.forEach(element => {
                this.bothLineName.push(
                    element.attId + '月'
                )
                this.bothLineValue.push(
                    element.count
                )
            });
        },
        //各年级应用统计
        async getapplyStatByGrade() {
            this.Dbarname = [];
            this.Dbarvalue = [];
            this.DbarmaxValue = [];
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let resData = await this.$Api.DataScreen.getapplyStatByGrade(data);
            resData.data.forEach(element => {
                this.Dbarname.push(element.indexRank + '年级');
                this.Dbarvalue.push(element.result);
            });
            resData.data.forEach(element => {
                this.DbarmaxValue.push(Math.max(...this.Dbarvalue) + Math.max(...this.Dbarvalue) * 0.2);
            });
        },
        //各省市不同课型应用情况
        async getApplyDetail() {
            this.ApplicationData = [];
            this.lessontypeName = [];
            this.ApplicationMaxData = [];
            this.lessontypeValue = {
                data1: [],
                data2: [],
                data3: [],
                data4: [],
            };
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let resData = await this.$Api.DataScreen.getApplyDetail(data);
            if (resData.data.length == 0) return false;
            this.ApplicationData = resData.data;
            let maxdata = [];
            resData.data.forEach(item => {
                if (this.termId == '20231' || this.termId == '20232') {
                    maxdata.push(item.syncCourse + item.wordOfDay + item.hardPenSys + item.softPenSys)
                    this.lessontypeValue['data2'].push(item.wordOfDay || 0);
                } else {
                    maxdata.push(item.syncCourse + item.conciseCourse + item.hardPenSys + item.softPenSys)
                    this.lessontypeValue['data2'].push(item.conciseCourse || 0);
                }
                this.lessontypeName.push(item.name);
                this.lessontypeValue['data1'].push(item.syncCourse || 0);
                this.lessontypeValue['data3'].push(item.hardPenSys || 0);
                this.lessontypeValue['data4'].push(item.softPenSys || 0);
            });
            resData.data.forEach(item => {
                this.ApplicationMaxData.push(Math.max(...maxdata));

            })
        },
        //各地区应用情况（全国）
        async getapplyByNationwide() {
            this.mapData = [];
            this.situationApp = [];
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let that = this;
            let resData = await this.$Api.DataScreen.getapplyByNationwide(data);
            resData.data.forEach((item) => {
                if (item.applyNum == 0) return false;
                this.mapData.push(
                    {
                        name: that.dispose(item.name),
                        value: item.applyNum,
                        id: item.areaId
                    }
                )
            });
            this.situationApp = resData.data;
            this.situationApp.sort((a, b) => b.schoolNum - a.schoolNum);
            this.situationApp.sort((a, b) => b.teaNum - a.teaNum);
            this.situationApp.sort((a, b) => b.joinRate - a.joinRate);
            this.situationApp.sort((a, b) => b.applyNum - a.applyNum);
            this.$nextTick(() => {
                this.map_graphics();
            });
        },
        //各地区测评情况（全国）
        async getEval() {
            this.testSituation = [];
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let resData = await this.$Api.DataScreen.getEval(data);
            if (resData.data) {
                this.testSituation = resData.data;
                this.testSituation.sort((a, b) => b.stuNum - a.stuNum);
                this.testSituation.sort((a, b) => b.evalTotalNum - a.evalTotalNum);
                this.testSituation.sort((a, b) => b.unifyEvalNum - a.unifyEvalNum);
                this.testSituation.sort((a, b) => b.goodNum - a.goodNum);
            }

        },
        //各时段不同课型应用情况
        async getCourseHourLogByAction() {
            this.timeFrame = [];
            let data = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let resData = await this.$Api.DataScreen.getCourseHourLogByAction(data);
            if (this.termId == '20231' || this.termId == '20232') {
                if (resData.data['syncCourse'].length == 0 && resData.data['wordOfDay'].length == 0 && resData.data['hardPenSys'].length == 0 && resData.data['softPenSys'].length == 0) return false;
                this.timeFrame[1] = resData.data['wordOfDay'];
            } else {
                if (resData.data['syncCourse'].length == 0 && resData.data['conciseCourse'].length == 0 && resData.data['hardPenSys'].length == 0 && resData.data['softPenSys'].length == 0) return false;
                this.timeFrame[1] = resData.data['conciseCourse'];
            }
            this.timeFrame[0] = resData.data['syncCourse'];
            this.timeFrame[2] = resData.data['hardPenSys'];
            this.timeFrame[3] = resData.data['softPenSys'];
        },
        //教师应用详情Excel导出
        async getexcelByNationwide() {
            this.getexcelByNationwideState = false;
            let datas = {
                termKey: this.termId,
            };
            let res = await this.$Api.DataScreen.getexcelByNationwide(datas)
            if (res.code === 200 && res.data) {
                window.open(res.data);
                this.$notify({
                    title: '成功',
                    message: '整体统计报表报表',
                    type: 'success'
                });
            } else {
                console.error('下载报错');
                this.$notify.error({
                    title: '生成失败',
                    message: '整体统计报表下载失败',
                });
            }
            this.getexcelByNationwideState = true;
        },
        //省份添加
        dispose(name) {
            if (name == '北京' || name == '天津' || name == '上海' || name == '重庆') {
                return name + '市';
            } else if (name == '香港' || name == '澳门') {
                return name + '特别行政区';
            } else if (name == '内蒙古') {
                return name + '自治区';
            } else if (name == '广西') {
                return name + '壮族自治区';
            } else if (name == '西藏') {
                return name + '自治区';
            } else if (name == '宁夏') {
                return name + '回族自治区';
            } else if (name == '新疆') {
                return name + '维吾尔自治区';
            } else {
                return name + '省';
            }
        },
        getback() {
            this.$router.push("/home");
        },
        // 判断是否项目校
        async getqueryUserMaxOrg() {
            let resData = await this.$Api.Home.queryUserMaxOrg();
            this.statistics = resData.data.statistics;
        },

        //全国每月应用统计导出
        async getexcelByNationwideByMonthWeek() {
            this.getexcelByNationwideByMonthWeekState = false;
            let datas = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            let res = await this.$Api.DataScreen.getexcelByNationwideByMonthWeek(datas);
            if (res.code === 200 && res.data) {
                window.open(res.data)
                this.$notify({
                    title: '成功',
                    message: '每月应用统计报表',
                    type: 'success'
                });
            } else {
                console.error('下载报错');
                this.$notify.error({
                    title: '生成失败',
                    message: '每月应用统计报表下载失败',
                });
            }
            this.getexcelByNationwideByMonthWeekState = true;
        },
        //全国各省市应用情况导出
        getapplyByNationwideExcel() {
            this.getapplyByNationwideExcelState = false;
            let datas = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            this.$Api.DataScreen.getapplyByNationwideExcel(datas)
                .then(res => {
                    if (res && res.size > 0) {
                        const link = document.createElement('a')
                        const blob = new Blob([res], {
                            type: 'application/x-msdownload'
                        });
                        const fileName = '各省市应用情况导出';
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.download = fileName + '.xls';
                        link.click();
                        document.body.removeChild(link);
                        this.$notify({
                            title: '成功',
                            message: '各省市应用情况报表',
                            type: 'success'
                        });
                    } else {
                        this.$notify.error({
                            title: '生成失败',
                            message: '各省市应用情况报表下载失败',
                        });
                    }
                    this.getapplyByNationwideExcelState = true;
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //全国各省市测评情况导出
        async getEvalExcel() {
            this.getEvalExcelState = false;
            let datas = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            this.$Api.DataScreen.getEvalExcel(datas)
                .then(res => {
                    if (res && res.size > 0) {
                        const link = document.createElement('a')
                        const blob = new Blob([res], {
                            type: 'application/x-msdownload'
                        });
                        const fileName = '各省市测评情况导出';
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.download = fileName + '.xls';
                        link.click();
                        document.body.removeChild(link);
                        this.$notify({
                            title: '成功',
                            message: '各省市测评情况报表',
                            type: 'success'
                        });
                    } else {
                        this.$notify.error({
                            title: '生成失败',
                            message: '各省市测评情况报表下载失败',
                        });
                    }
                    this.getEvalExcelState = true;
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //全国各省市不同课型应用情况导出
        async getApplyDetailExcel() {
            this.getApplyDetailExcelState = false;
            let datas = {
                termKey: this.termId,
                level: -1,
                areaId: 0,
            };
            this.$Api.DataScreen.getApplyDetailExcel(datas)
                .then(res => {
                    if (res && res.size > 0) {
                        const link = document.createElement('a')
                        const blob = new Blob([res], {
                            type: 'application/x-msdownload'
                        });
                        const fileName = '各省市不同课型应用情况导出';
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.download = fileName + '.xls';
                        link.click();
                        document.body.removeChild(link);
                        this.$notify({
                            title: '成功',
                            message: '各省市不同课型应用情况报表',
                            type: 'success'
                        });
                    } else {
                        this.$notify.error({
                            title: '生成失败',
                            message: '各省市不同课型应用情况报表下载失败',
                        });
                    }
                    this.getApplyDetailExcelState = true;
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
    },
    // 生命周期，创建完成时（可以访问当前this实例）
    created() {

    },
    // 生命周期：挂载完成时（可以访问DOM元素）
    mounted() {
        // this.map_graphics();
        this.getTermList();
        this.getarea();
        this.getqueryUserMaxOrg();

    },
    beforeCreate() { },//生命周期：创建之前
    beforeMount() { },//生命周期：挂载之前
    beforeUpdate() { },//生命周期：更新之前
    updated() { },//生命周期：更新之后
    beforeDestroy() { },//生命周期：销毁之前
    destroyed() { },//生命周期：销毁完成
    activated() { },//如果页面有keep-alive缓存功能，这个函数会触发执行
}
</script>
<style lang="less" scoped>
@import "./less/nationwideData.less";
</style>